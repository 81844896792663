import { graphql } from "gatsby";
import React from "react";
import BalanzUniversityLayout from "../../../components/glosario-financiero/BalanzUniveristyLayout";
import GlosarioList from "../../../components/glosario-financiero/GlosarioList";

const pageData = {
    title: 'Artículos de Educación Financiera | Balanz University',
    description: 'Leé nuestros artículos destacados y aprendé todo sobre el mercado de capitales, finanzas personales, activos financieros, análisis técnico y más.',
    bannerText: null
};

const BalanzUniversityArts = ({data}) => {
    return <BalanzUniversityLayout pageData={pageData}>
       <GlosarioList artdata={data.allGlossaries.edges} />
    </BalanzUniversityLayout>
}

export const query = graphql`
query {
    allGlossaries {
        edges {
          node {
            faq_glosario
            description
            htmlcontent
            htmldesc
            letra
            metatitle
            slug
            title
            id
          }
        }
      }
}
`
export default BalanzUniversityArts